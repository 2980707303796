<template>
  <div class="page-container login">
    <div class="page-header">
      <ace-header show-back :back-func="onBack">
        {{ $t('system.login.loginButton') }}
      </ace-header>
    </div>
    <div class="page-body">
      <van-form
        validate-first
        :show-error-message="false"
        validate-trigger="onSubmit"
        @submit="onSubmit"
        @failed="onFailed"
      >
        <div class="mx-4px">
          <van-field
            v-model="formData.email"
            clearable
            maxlength="50"
            autocomplete="off"
            name="email"
            :placeholder="$t('system.login.email')"
            :rules="[{ pattern: emailReg, message: $t('system.login.emailValidateMsg') }]"
            class="mt-12px"
          />
          <van-field
            v-model="formData.password"
            :type="showPwd ? 'text' : 'password'"
            clearable
            maxlength="30"
            autocomplete="off"
            name="password"
            :placeholder="$t('system.login.password')"
            :rules="[{ pattern: pwdReg, message: $t('system.login.passwordValidateMsg') }]"
            class="mt-12px"
          >
            <template #right-icon>
              <van-icon
                :name="showPwd ? 'eye' : 'closed-eye'"
                size="20px"
                @click="showPwd = !showPwd"
              />
            </template>
          </van-field>
          <div class="mt-30px mx-14px">
            <router-link to="/find-password-email">
              {{ $t('system.login.forgetPassword') }}
            </router-link>
          </div>
          <div class="mt-30px mx-16px">
            <van-button
              block
              type="primary"
              :disabled="disabled"
              :loading="loading"
              native-type="submit"
            >
              {{ $t('system.login.loginButton') }}
            </van-button>
          </div>
        </div>
      </van-form>
      <div class="mt-20px mx-20px">
        <van-button block to="/register">
          {{ $t('system.login.freeAccountCreate') }}
        </van-button>
      </div>
      <login-o-auth :auth-list="authList" @on-authed="onAuthed" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { userLogin, authLogin } from '@/api/user';
  import LoginOAuth from '@/components/LoginOAuth.vue';

  export default {
    name: 'Login',
    components: { LoginOAuth },
    data() {
      return {
        emailReg: /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/,
        pwdReg: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,30}$/,
        disabled: true,
        loading: false,
        formData: {
          email: '',
          password: '',
        },
        showPwd: false,
      };
    },
    computed: {
      ...mapState({
        authList: (state) => state.system.oAuthList,
      }),
    },
    methods: {
      ...mapActions({
        setUserInfo: 'setUserInfo',
      }),
      onBack() {
        this.$router.replace({ name: 'home' });
      },
      async onSubmit(values) {
        try {
          this.loading = true;
          const res = await userLogin({
            password: values.password,
            email: values.email,
            login_way: 1,
          });
          if (res && res.user_token) {
            this.$notify({
              type: 'success',
              message: this.$t('system.login.loginSucceed'),
            });
            const { email, user_token } = res;
            this.setUserInfo({
              userEmail: email,
              userToken: user_token,
            });
            const { redirect } = this.$route.query;
            if (redirect) {
              this.$router.replace({ path: redirect });
            } else {
              this.$router.go(-1);
            }
          }
        } finally {
          this.loading = false;
        }
      },
      onFailed({ errors }) {
        this.$toast(errors[0].message);
      },
      async onAuthed(data) {
        if (data) {
          const { register_way, token, platform } = data;
          const res = await authLogin({ register_way, token });
          if (res && res.user_token) {
            this.setUserInfo({
              userEmail: res.email,
              userToken: res.user_token,
              oAuthedPlatform: platform,
            });
            this.$notify({
              type: 'success',
              message: this.$t('system.login.loginSucceed'),
            });
            const { redirect } = this.$route.query;
            if (redirect) {
              this.$router.replace({ path: redirect });
            } else {
              this.$router.go(-1);
            }
          }
        }
      },
    },
    watch: {
      formData: {
        handler(data) {
          if (data.email && data.password) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
