<template>
  <div class="mt-30px px-20px oauth">
    <div class="oauth-title">
      <span>{{ $t('system.login.otherSignIn') }}</span>
    </div>
    <ul class="oauth-content">
      <li v-for="item in authList" :key="item.full_name" @click="handleClick(item)">
        <img class="oauth-icon" :src="item.ico" :alt="item.full_name" />
      </li>
    </ul>
  </div>
</template>

<script>
  import FacebookAuth from './OAuth/FacebookAuth';
  import GoogleAuth from './OAuth/GoogleAuth';

  export default {
    name: 'LoginOAuth',
    props: {
      authList: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      async handleClick(data) {
        this.$toast.loading({
          message: this.$t('common.loadingText'),
          duration: 0,
        });
        try {
          if (data.full_name === 'facebook') {
            await FacebookAuth.subscribe(
              {
                appId: process.env.VUE_APP_FACEBOOK_ID,
                version: 'v9.0',
                cookie: true,
                xfbml: true,
                autoLogAppEvents: true,
              },
              this.$i18n.locale
            );
            const fbRes = await FacebookAuth.login({ scope: 'email' });
            if (fbRes.authResponse) {
              this.$emit('on-authed', {
                register_way: data.register_way,
                token: fbRes.authResponse.accessToken,
                platform: data.full_name,
              });
            }
          }
          if (data.full_name === 'google') {
            await GoogleAuth.load({
              client_id: process.env.VUE_APP_GOOGLE_ID,
            });
            const googleUser = await GoogleAuth.signIn();
            const id_token = googleUser.getAuthResponse().id_token;
            if (id_token) {
              this.$emit('on-authed', {
                register_way: data.register_way,
                token: id_token,
                platform: data.full_name,
              });
            }
          }
        } catch (error) {
          this.$notify({ type: 'danger', message: this.$t('system.api.apiRequestFailed') });
        } finally {
          this.$toast.clear();
        }
      },
    },
    beforeDestroy() {
      this.$toast.clear();
    },
  };
</script>

<style scoped>

  .oauth-title {
    position: relative;
    margin-bottom: 20px;
    height: 16px;
    text-align: center;
    line-height: 16px;
  }
  .oauth-title::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 7px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #31363e;
  }
  .oauth-title span {
    position: relative;
    z-index: 1;
    display: inline-block;
    min-width: 40%;
    background-color: #191b1f;
  }
  .oauth-content {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .oauth-content li {
    padding: 0 16px;
    cursor: pointer;
  }
  .oauth-icon {
    width: 24px;
    height: 24px;
  }

</style>
